<template>
  <div class="wrapper-box">
    <el-card>
      <div slot="header" class="vd_clearfix">
        <span class="Recently"
        >推送提醒 <span class="el-icon-refresh" :class="{ vg_rotate: rotateFlag }" size="mini" @click="loadingChange"
                        circle></span
        ></span>
      </div>
      <div class="card_body">
        <el-row v-for="(item, index) in pushList" :key="index">
          <el-badge :value="item.perm_icon" class="vd_item">
            <el-link :underline="false" class="vg_mr_8 vg_pointer" @click="goPermList(item.perm_url)">{{
                item.perm_name
              }}
            </el-link>
          </el-badge>
        </el-row>
        <!--        <el-row v-for="(item, index) in pushList" :key="index">
          <el-badge v-if="item.icon === 1" :value="mstkNum" class="vd_item">
            <el-link :underline="false" class="vg_mr_8 vg_pointer vd_borb" @click="goPermList(item.perm_url)">
              {{ item.perm_name }}
            </el-link>
          </el-badge>
          <el-badge v-if="item.icon === 2" :value="mtrlNum" class="vd_item">
            <el-link :underline="false" class="vg_mr_8 vg_pointer vd_borb" @click="goPermList(item.perm_url)">
              {{ item.perm_name }}
            </el-link>
          </el-badge>
          <el-badge v-if="item.icon === 3" :value="mailNum" class="vd_item">
            <el-link :underline="false" class="vg_mr_8 vg_pointer vd_borb" @click="goPermList(item.perm_url)">
              {{ item.perm_name }}
            </el-link>
          </el-badge>
          <el-badge v-if="item.icon === 4" :value="requNum" class="vd_item">
            <el-link :underline="false" class="vg_mr_8 vg_pointer vd_borb" @click="goPermList(item.perm_url)">
              {{ item.perm_name }}
            </el-link>
          </el-badge>
          <el-badge v-if="item.icon === 5" :value="requNum1" class="vd_item">
            <el-link :underline="false" class="vg_mr_8 vg_pointer vd_borb" @click="goPermList(item.perm_url)">
              {{ item.perm_name }}
            </el-link>
          </el-badge>
          <el-badge v-if="item.icon === 6" :value="smplNum" class="vd_item">
            <el-link :underline="false" class="vg_mr_8 vg_pointer vd_borb" @click="goPermList(item.perm_url)">
              {{ item.perm_name }}
            </el-link>
          </el-badge>
        </el-row>-->
      </div>
    </el-card>
  </div>
</template>

<script>
import {get, getNoCatch} from '@api/request';
import {dataAPI} from '@api/modules/data';
import bus from '@/components/common/bus';

export default {
  name: 'boardPush',
  data() {
    return {
      pushList: [
        {
          perm_url: '/mstk_push_list_9001',
          perm_name: '库存警告提醒',
          icon: 1
        },
        {
          perm_url: '/mtrl_push_list_9002',
          perm_name: '物料0元提醒',
          icon: 2
        },
        {
          perm_url: 'mail_push_list_9003',
          perm_name: '邮件发送提醒',
          icon: 3
        },
        {
          perm_url: 'requ_push_list_9004',
          perm_name: '委托接收单提醒',
          icon: 4
        },
        {
          perm_url: 'requ_push_list_9005',
          perm_name: '采购退样提醒',
          icon: 5
        },
        {
          perm_url: 'smpl_push_list_9006',
          perm_name: '工艺信息完善提醒',
          icon: 6
        },
        {
          perm_url: '/push_9009',
          perm_name: '报价单退回提醒',
          icon: 7
        }
      ],
      rotateFlag: false
    };
  },
  created() {
  },
  mounted() {
    this.initData();
    bus.$on('refreshReminderCount', msg => {
      this.initData();
    });
  },
  methods: {
    initData() {
      this.getPermInfo();
      this.getDataMstf();
      this.getDataMtrl();
      this.getDataMail();
      this.getDataRequ();
      this.getDataRequ1();
      this.getSmplPushCount();
      this.cancelSampleReminderCount();
      this.syncAttachmentReminderCount();
      this.getPush9009();
      setTimeout(() => {
        this.rotateFlag = false;
      }, 1100);
    },
    // 获取库存数字
    getDataMstf() {
      let temp = this.pushList.find(item => item.perm_id === 9001)
      if (!temp) return;
      getNoCatch(dataAPI.countPush9001).then(({data}) => {
        temp.perm_icon = data.countMstk;
      });
    },
    // 获取物料数字
    getDataMtrl() {
      let temp = this.pushList.find(item => item.perm_id === 9002)
      if (!temp) return;
      getNoCatch(dataAPI.countPush9002).then(({data}) => {
        temp.perm_icon = data.countMstk;
      });
    },
    // 获取邮件数字
    getDataMail() {
      let temp = this.pushList.find(item => item.perm_id === 9003)
      if (!temp) return;
      get(dataAPI.countPush9003).then(({data}) => {
        temp.perm_icon = data.countMail;
      });
    },
    // 获取委托数字
    getDataRequ() {
      let temp = this.pushList.find(item => item.perm_id === 9004)
      if (!temp) return;
      getNoCatch(dataAPI.countPush9004, {requ_push_state: 1}).then(({data}) => {
        temp.perm_icon = data.countRequ;
      });
    },
    // 获取委托数字
    getDataRequ1() {
      let temp = this.pushList.find(item => item.perm_id === 9005)
      if (!temp) return;
      getNoCatch(dataAPI.countPush9004, {requ_push_state: 2}).then(({data}) => {
        temp.perm_icon = data.countRequ;
      });
    },
    //获取完善信息count
    getSmplPushCount() {
      let temp = this.pushList.find(item => item.perm_id === 9006)
      if (!temp) return;
      getNoCatch(dataAPI.count_push_9005).then(({data}) => {
        temp.perm_icon = data.countSmpl;
      });
    },
    // 取消打样提醒Count
    cancelSampleReminderCount() {
      let temp = this.pushList.find(item => item.perm_id === 9007)
      if (!temp) return;
      getNoCatch(dataAPI.count_push_9007).then(({data}) => {
        temp.perm_icon = data;
      });
    },
    // 同步附件提醒Count
    syncAttachmentReminderCount() {
      let temp = this.pushList.find(item => item.perm_id === 9008)
      if (!temp) return;
      getNoCatch(dataAPI.count_push_9008).then(({data}) => {
        temp.perm_icon = data;
      });
    },
    //报价单退回提醒count
    getPush9009() {
      let temp = this.pushList.find(item => item.perm_id === 9009)
      if (!temp) return;
      getNoCatch(dataAPI.count_push_9009).then(({data}) => {
        temp.perm_icon = data;
      });
    },
    //获取perm信息
    getPermInfo() {
      this.pushList = JSON.parse(this.$cookies.get('push'));
    },
    goPermList(val) {
      this.jump(val);
    },
    loadingChange() {
      this.rotateFlag = true;
      this.initData();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_item {
  margin-top: 10px;
  margin-right: 40px;
}

.card_body {
  padding-right: 10px;
  overflow: auto;
}

@media screen and (min-width: 993px) {
  .card_body {
    height: 350px;
  }
}

@media screen and (max-width: 992px) {
  .card_body {
    height: 700px;
  }
}

.vd_borb {
  margin-bottom: 20px;
}

.vd_borb :hover {
  box-shadow: 2px 2px 3px #cccccc;
}

.el-icon-refresh {
  cursor: pointer;
}

.vd_clearfix {
  display: flex;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;
  }
}

.vd_ml12 {
  margin-left: 12px;
}

.Recently {
  i {
    font-size: 10px;
    font-style: inherit;
    color: #ccc;
    margin-top: 5px;
  }

  span {
    margin: 0 10px;
    margin-top: 3px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;

    &:hover {
      color: blue;
    }
  }
}
</style>
