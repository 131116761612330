<template>
  <div class="vg_wrapper">
    <el-card>
      <div slot="header" class="vd_clearfix">
        <span class="Recently">
          <h2>委托接收单提醒:</h2>
          <span :class="{ vg_rotate: rotateFlag, 'el-icon-refresh': true }" @click="buttonRefresh"/>
          <i>(最近20条)</i>
        </span>
        <el-button type="text" class="vd_card_button" @click="goPush9004()">查看更多》</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border v-loading="loading" class="mtrTab">
            <el-table-column label="序号" width="50px" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column label="时间" prop="requ_push_time" width="150px" :formatter="formatDate"/>
            <el-table-column label="文本" prop="requ_push_text" width="auto">
              <template v-slot="scope">
                <el-tooltip popper-class="item" effect="dark" placement="top">
                  <div slot="content" class="item">
                    {{ scope.row.requ_push_text }}
                  </div>
                  <span
                      style="
                      display: -webkit-box;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      white-space: pre-line;
                    "
                  >{{ scope.row.requ_push_text }}</span
                  >
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="center" width="140px">
              <template slot-scope="scope">
                <div style="display: flex;justify-content: center">
                  <el-link
                      class="vg_mr_8 vg_cursor"
                      type="primary"
                      size="mini"
                      @click="jump('/requ_edit', { perm_id: 111, form_id: scope.row.requ_id })"
                  >查看
                  </el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get} from '@api/request';
import {requAPI} from '@api/modules/requ';
import bus from '@/components/common/bus';

export default {
  name: 'DashRequPush9004',
  data() {
    return {
      tableData: [],
      loading: true,
      rotateFlag: false
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    bus.$on('dashRequPush9004', msg => {
      this.initData();
    });
  },
  methods: {
    initData() {
      this.getRequPush();
    },
    //获取数据
    getRequPush() {
      get(requAPI.getRequPush, {requ_push_state: 1, size: 20})
          .then(res => {
            if (res.data.code === 0) {
              this.tableData = res.data.data.list;
              setTimeout(() => {
                this.loading = false;
                this.rotateFlag = false;
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    //跳转RequList页面
    goRequList(row) {
      get(requAPI.updateRequPushTotal, {requpush_id: row.requpush_id})
          .then(res => {
            if (res.data.code !== 0) {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
            this.initData();
            //刷新首页推送数量
            bus.$emit('refreshReminderCount');
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toTime13(row.requ_push_time);
    },
    // 刷新按钮
    buttonRefresh() {
      this.rotateFlag = true;
      this.getRequPush();
      this.loading = true;
    },
    goPush9004() {
      this.jump('/requ_push_list_9004')
    }
  }
};
</script>

<style scoped lang="scss">
.vd_completed {
  color: $color-secondary-text;
}

.item {
  max-width: 1500px !important; /* 根据需要设置 */
}

.mtrTab {
  margin-top: 10px;
  cursor: pointer;
}

.vd_clearfix {
  display: flex;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;
  }
}

.Recently {
  i {
    font-size: 13px;
    font-style: inherit;
    color: #ccc;
    margin-top: 5px;
  }

  span {
    margin: 0 10px;
    margin-top: 3px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
  }
}
</style>
